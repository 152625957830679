/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: white radial-gradient(circle, transparent 1%, white 1%)
    center/15000%;
}
.ripple:active {
  background-color: #bcc8da;
  background-size: 100%;
  transition: background 0s;
}
