body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef2f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 18px;
}

h1 {
  text-align: center;
  margin: 22px 0;
  margin-top: 32px;
}

.search-box {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  padding: 12px 22px;
  margin: 20px 0;
  font-size: 22px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #909db0;
  background-color: transparent;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  /* box-shadow: 0.4px 1.5px 3px #bcc8da; */
}

.search-box:focus {
  border-color: white;
  background-color: #fff;
  box-shadow: 1px 3px 10px #bcc8da;
}

.song-list-box {
  box-shadow: 0.4px 1.5px 3px #bcc8da;
  border-radius: 10px;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  padding: 10px 22px;
  margin: 10px 0;
}

.song-list-box:hover {
  box-shadow: 1px 3px 10px #bcc8da;
}

.song-list-title {
  font-weight: 500;
  margin: 8px 0;
  color: black;
}

.song-list-book-name {
  font-size: 12px;
  margin: 8px 0;
  color: #576374;
}

.error-display {
  border-radius: 10px;
  background-color: #ffc3c3;
  padding: 12px;
  border: 1px solid #ff6060;
}

/* Ripple effect */
.ripple {
  background-position: center;
  -webkit-transition: background 0.8s;
  transition: background 0.8s;
}
.ripple:hover {
  background: white -webkit-radial-gradient(circle, transparent 1%, white 1%)
    center/15000%;
  background: white radial-gradient(circle, transparent 1%, white 1%)
    center/15000%;
}
.ripple:active {
  background-color: #bcc8da;
  background-size: 100%;
  -webkit-transition: background 0s;
  transition: background 0s;
}

.icon-button {
  margin: 5px;
  font-size: 22px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #fff;
  box-shadow: 0.5px 1.3px 4px #bcc8da;
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  width: 50px;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
}

.icon-button:hover {
  box-shadow: 1px 3px 10px #bcc8da;
}

